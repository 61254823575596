import React from 'react'
import { MDBFooter, MDBContainer } from 'mdb-react-ui-kit';

const Footer = () => {
  return (
      <MDBFooter className='text-center text-dark' >
          <div className='text-center p-3 bg-light'>
              © 2024 Copyright - <a className='text-dark' href='https://Dencker.no/'>
                  Dencker.no
              </a>
          </div>
      </MDBFooter>
  )
}

export default Footer