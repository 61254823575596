import { MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import ContactForm from '../../components/contactForm/ContactForm'
import './Cta.css'

const Cta = () => {
  return (
        <div class="parallax-container">
          <div class="parallax-background"></div>
    <MDBContainer fluid className='mt-5'>
        <MDBContainer className='p-5' id='CTA_wrapper'>
              <MDBRow id='CTA_Row' className='p-0'>
                <MDBCol md='7' className='justify-content-center d-flex flex-column' id='CTA-text_wrapper'>
                    <MDBTypography variant='h3' className='text-uppercase text-dark display-6 fw-bold'>
                        Det beste alternativet <br />
                          <span className='text-rgb'>for din bedrift</span>
                    </MDBTypography>
                    <MDBTypography variant='p' className='text-secondary lead w-75 my-2' id='CTA_text-p'>
                          Når du velger oss, velger du mer enn bare et IT-system – du velger en partner som forstår dine behov. Våre løsninger er utviklet for å være fleksible, skalerbare og tilpasset nettopp din bedrift. Med rask implementering, brukervennlige grensesnitt og uovertruffen kundesupport, sikrer vi at du får den beste opplevelsen fra dag én. Vi er her for å hjelpe din bedrift med å vokse og lykkes i en digital verden.
                    </MDBTypography>
                  </MDBCol>
                <MDBCol md='5' className='rounded card my-3 p-4 shadow-3-strong bg-light' id='CTA-form_wrapper'>
            <ContactForm />
                  </MDBCol>
            </MDBRow>
        </MDBContainer>
    </MDBContainer>
    </div>
  )
}

export default Cta