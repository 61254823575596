import React, { useState } from 'react';
import './Navbar.css'
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse,
} from 'mdb-react-ui-kit';

import Logo from '../../assets/logo.png'

const Navbar = () => {
    const [openBasic, setOpenBasic] = useState(false);

  return (
      <MDBNavbar expand='lg' light bgColor='light'>
          <MDBContainer fluid>
              <MDBNavbarBrand href='#'>
                <img src={Logo} alt="" height="64px" />
              </MDBNavbarBrand>

              <MDBNavbarToggler
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                  onClick={() => setOpenBasic(!openBasic)}
              >
                  <MDBIcon icon='bars' fas />
              </MDBNavbarToggler>

              <MDBCollapse navbar open={openBasic}>
                  <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                      <MDBNavbarItem>
                          <MDBNavbarLink active aria-current='page' href='#'>
                              HJEM
                          </MDBNavbarLink>
                      </MDBNavbarItem>
                      <MDBNavbarItem>
                          <MDBNavbarLink active aria-current='page' href='#'>
                              REFERANSER
                          </MDBNavbarLink>
                      </MDBNavbarItem>
                      <MDBNavbarItem>
                          <MDBNavbarLink active aria-current='page' href='#'>
                              OM OSS
                          </MDBNavbarLink>
                      </MDBNavbarItem>
                      <MDBNavbarItem>
                          <MDBNavbarLink active aria-current='page' href='#'>
                              PRISER
                          </MDBNavbarLink>
                      </MDBNavbarItem>
                      <MDBNavbarItem>
                          <MDBNavbarLink active aria-current='page' href='#'>
                              KONTAKT
                          </MDBNavbarLink>
                      </MDBNavbarItem>

                      <MDBNavbarItem>
                          <MDBDropdown>
                              <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                                  SYSTEMER
                              </MDBDropdownToggle>
                              <MDBDropdownMenu>
                                  <MDBDropdownItem link>SELVHJELP</MDBDropdownItem>
                                  <MDBDropdownItem link>Another action</MDBDropdownItem>
                                  <MDBDropdownItem link>Something else here</MDBDropdownItem>
                              </MDBDropdownMenu>
                          </MDBDropdown>
                      </MDBNavbarItem>
                  </MDBNavbarNav>
              </MDBCollapse>
          </MDBContainer>
      </MDBNavbar>
  )
}

export default Navbar