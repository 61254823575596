import { MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import Akershus_Fylkeskommune from '../../assets/akershus_logo.png'
import './Brand.css'

const Brand = () => {
  return (
    <MDBContainer fluid className='text-center p-4'>
          <div className='d-flex flex-wrap justify-content-center my-3'>
              <div className='p-5 justify-content-center d-flex' >
          <img src="https://img.logoipsum.com/311.svg" alt="" className='brand_logo' style={{ height: '40px' }} />
              </div>
              <div className='p-5 justify-content-center d-flex' >
          <img src="https://img.logoipsum.com/284.svg" alt="" className='brand_logo' style={{ height: '40px' }} />
              </div>
              <div className='p-5 justify-content-center d-flex' >
          <img src={Akershus_Fylkeskommune} alt="" className='brand_logo' style={{ height: '40px' }} />
              </div>
              <div className='p-5 justify-content-center d-flex' >
          <img src="https://www.freeiconspng.com/uploads/black-nike-emblem-png-hd-0.png" alt="" className='brand_logo' style={{ height: '40px' }} />
              </div>
              <div className='p-5 justify-content-center d-flex' >
          <img src="https://img.logoipsum.com/317.svg" alt="" className='brand_logo' style={{ height: '40px' }} />
              </div>
              <div className='p-5 justify-content-center d-flex' >
          <img src="https://img.logoipsum.com/239.svg" alt="" className='brand_logo' style={{ height: '40px' }} />
              </div>
          </div>
    </MDBContainer>
  )
}

export default Brand