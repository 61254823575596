import { MDBContainer, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import Brand from '../../components/brand/Brand'

const Customers = () => {
  return (
    <div className='mt-5'>
        <MDBContainer className='text-center p-5'>
        <MDBTypography variant='h2'>
            Våre kunder
        </MDBTypography>
        <MDBTypography variant='p' className='text-muted mt-3'>
          Vi er stolte over å ha flere store og anerkjente aktører blant våre kunder, som for eksempel Akershus Fylkeskommune. De, og mange andre, stoler på våre systemer for å effektivisere og forbedre sin IT-drift. Våre løsninger er skreddersydd for å møte de høye kravene til både offentlige og private virksomheter, og vi leverer alltid kvalitet, pålitelighet og førsteklasses support.
        </MDBTypography>
          </MDBContainer>
        <Brand />
    </div>
  )
}

export default Customers