import {
    MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBCol,
    MDBTypography,
    MDBTextArea
} from 'mdb-react-ui-kit';

import React from 'react'


const ContactForm = () => {
    return (
        <MDBContainer fluid>
            <MDBTypography variant='h5' className='text-dark text-center mb-5'>
                Send en melding
            </MDBTypography>
            <form className='m-3'>
                <MDBInput id='form4Example1' labelClass='text-primary' wrapperClass='mb-4' label='Navn' />
                <MDBInput type='email' id='form4Example2' labelClass='text-primary' wrapperClass='mb-4' label='E-post' />
                <MDBTextArea wrapperClass='mb-4' labelClass='text-primary' style={{resize: 'none'}} textarea id='form4Example3' rows={5} label='Beskjed' />

                <MDBCheckbox
                    wrapperClass='d-flex justify-content-start mb-4'
                    id='form4Example4'
                    label='Jeg er ikke en robot'
                    labelClass='text-dark'
                />

                <MDBBtn type='submit' className='mt-5' block>
                    Send
                </MDBBtn>
            </form>
        </MDBContainer>
    )
}

export default ContactForm