import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import hero_Illustration from '../../assets/hero_illustration.png'
import HeroStats from '../../components/heroStats/HeroStats'
import './Hero.css'

const Hero = () => {
  return (
    <MDBContainer fluid id="hero_wrapper" className='bg-light p-5'>
            <MDBRow className='align-items-center' id='hero_row'>
                <MDBCol md='6'>
                    <MDBTypography tag='bold' variant='h1' className='fw-bold display-1 text-uppercase'>
                          Fyr opp bedriften med <span className='text-rgb'>smarte systemer!</span>
                    </MDBTypography>
                      <MDBTypography tag='h5' className='fw-normal mt-3'>
            Våre innovative løsninger er laget for å <span className='fw-bold text-primary-gradient'>effektivisere, automatisere og gi IT-avdelingen din et løft.</span> 🚀
                    </MDBTypography>
                      <MDBTypography tag='h5' className='fw-normal'>
                          La dine systemer gjøre jobben, slik at teamet ditt kan fokusere på det som virkelig teller.
                    </MDBTypography>
                          
                    <MDBRow className='justify-content-left flex-row mt-3'>
                        <MDBBtn color='primary' className='m-2 d-flex w-auto'>
                            Våre løsninger
                        </MDBBtn>
                        <MDBBtn color='secondary' outline className='m-2 d-flex w-auto'>Kontakt oss</MDBBtn>
                    </MDBRow>
                </MDBCol>
        <MDBCol md='4' className='p-5' id='Hero-stats_image-wrapper'>
                      <img src={hero_Illustration} alt="" className='d-flex img-fluid' id='Hero_Image' />
                </MDBCol>
            </MDBRow>
      <MDBRow className='align-items-end' style={{height: '250px'}}>
        <div id="Hero-stats_wrapper">
          <HeroStats />
        </div>
            </MDBRow>
    </MDBContainer>
  )
}

export default Hero