import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import './Features.css'

const Features = () => {
  return (
    <MDBContainer fluid>
        <MDBRow className='p-5' id='features_row'>
            <MDBCol md='5'
                  id='features_Image'
                  className='p-5 text-center bg-image rounded'
                  >
                  <div className='mask p-5 d-flex flex-column justify-content-center' id='features_Mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                  <MDBTypography variant='h2' tag='strong' className='text-light fs-1 fw-bold text-uppercase'>
                          Hvorfor er våre systemer det <span className='text-primary-gradient fw-bold text-decoration-underline'>beste</span> valget?
                  </MDBTypography>
                  <p className="text-secondary mt-3 p-3">
                      Våre systemer er utviklet med fokus på både ytelse og brukervennlighet. <br /> Vi vet at hver IT-avdeling har unike utfordringer, derfor tilbyr vi fleksible og skalerbare løsninger som tilpasses dine behov. <br /> Med topp moderne teknologi, rask implementering, og dedikert support, sikrer vi at du alltid ligger et skritt foran konkurrentene. <br /> <br />
                       Våre systemer gir deg muligheten til å jobbe smartere, raskere og mer kostnadseffektivt.
                  </p>
                  <div className="flex-row d-flex justify-content-center align-items-center">
                  <MDBBtn className='w-25 mx-2' color='primary'>Se priser</MDBBtn>
                  <MDBBtn className='w-25 mx-2' color='light' outline>Ta kontakt</MDBBtn>
            </div>
                  </div>
            </MDBCol>
              <MDBCol md='6' className='d-flex mx-auto justify-content-center'>
                  <div className='grid align-items-center justify-content-center d-flex flex-column'>
                    <div md='8' className='flex-row w-100 d-flex py-4 px-2 bg-secondary bg-opacity-10 my-2 '>
              <div className='d-flex bg-animated text-white justify-content-center align-items-center rounded mx-4' style={{height: '64px', width: '64px'}}>
                              <MDBIcon fas icon="mobile-alt" size='2x' />
                        </div>
                          <div md='8' className='g-col-6 w-100 px-3'>
                        <MDBTypography variant='h5'>
                            Responsivt design
                        </MDBTypography>
                        <p className="text-muted">
                            Våre systemer tilpasser seg alle enheter, enten det er mobil, nettbrett eller PC. Dette sikrer en sømløs opplevelse for brukerne dine, uansett hvor de er. Fleksibilitet og funksjonalitet i hver piksel. Med et responsivt design får du ikke bare et visuelt flott system, men også en løsning som forbedrer brukeropplevelsen og øker effektiviteten. Din digitale tilstedeværelse blir optimalisert for alle plattformer.
                        </p>
                          </div>
                    </div>
                      <div md='8' className='flex-row w-100 d-flex py-4 px-2 bg-secondary bg-opacity-10 my-2 '>
                        <div className='d-flex bg-animated text-white justify-content-center align-items-center rounded mx-4' style={{height: '64px', width: '64px'}}>
                              <MDBIcon fas icon="adjust" size='2x' />
                        </div>
                          <div md='8' className='g-col-6 w-100 px-3'>
                        <MDBTypography variant='h5'>
                            Farger
                        </MDBTypography>
                        <p className="text-muted">
                            Vi gir deg friheten til å velge dine egne farger, slik at systemene våre matcher bedriftens profil. Skap et unikt design som reflekterer din merkevare og skiller deg ut i mengden. Vi vet hvor viktig det er å opprettholde en visuell identitet, og derfor lar vi deg tilpasse alt fra farger til stiler. Resultatet? Et system som ser profesjonelt ut og harmonerer perfekt med resten av din virksomhet.
                        </p>
                          </div>
                    </div>
                    <div md='8' className='flex-row w-100 d-flex py-4 px-2 bg-secondary bg-opacity-10 my-2 '>
                        <div className='d-flex bg-animated text-white justify-content-center align-items-center rounded mx-4' style={{height: '64px', width: '64px'}}>
                              <MDBIcon fas icon="server" size='2x' />
                        </div>
                          <div md='8' className='g-col-6 w-100 px-3'>
                        <MDBTypography variant='h5'>
                            Hastighet
                        </MDBTypography>
                        <p className="text-muted">
                            Med servere lokalisert i Norge, der bedriften din holder til, garanterer vi lynraske responstider. Lokale servere gir ikke bare raskere tilgang, men også bedre stabilitet og pålitelighet. Våre servere er bygget med tanke på høy ytelse og minimal nedetid. Dette betyr at du kan forvente en stabil drift, noe som gir trygghet i hverdagen og bedre produktivitet for teamet ditt.
                        </p>
                          </div>
                    </div>
                    <div md='8' className='flex-row w-100 d-flex py-4 px-2 bg-secondary bg-opacity-10 my-2 '>
                        <div className='d-flex bg-animated text-white justify-content-center align-items-center rounded mx-4' style={{height: '64px', width: '64px'}}>
                              <MDBIcon fas icon="shield-alt" size='2x' />
                        </div>
                          <div md='8' className='g-col-6 w-100 px-3'>
                        <MDBTypography variant='h5'>
                            Sikkerhet
                        </MDBTypography>
                        <p className="text-muted">
                        Sikkerhet er vår høyeste prioritet. Med avansert kryptering og regelmessige sikkerhetsoppdateringer sørger vi for at dine data er trygge, slik at du kan fokusere på det som er viktigst. Våre systemer er designet med flere sikkerhetslag, fra brannmurer til innbruddsdeteksjon. Du kan være trygg på at informasjonen din håndteres med høyeste grad av konfidensialitet og beskyttelse mot eksterne trusler.
                        </p>
                          </div>
                    </div>
                </div>
            </MDBCol>
        </MDBRow>
    </MDBContainer>
  )
}

export default Features